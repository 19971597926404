<template>
  <div class="secondary-match-card">
    <div size="" style="text-align: center; padding: 0 10px">
      <img :src="match.image" alt="Trailblazers" />
    </div>
    <div size="1:1 3:4@medium 3:4@large" style="padding: 0 15px; text-align: center">
      <!--      <div class="badge badge-green" style="margin: 20px 0 0">{{ percent }}% Match</div>-->
      <div class="badge badge-green" style="margin: 20px 0 0">Career Match #{{ rank }}</div>
      <h1 type-style="display-3" style="font-size: 24px; padding-top: 0; margin-top: -25px; padding-bottom: 0 !important">
        {{ match.main_title }}
      </h1>
      <p type-style="body-3" class="mb-5 hide-on-mobile" style="font-size: 12px">
        {{ match.desktop_copy }}
      </p>
      <div class="stat-block">
        <div class="stat">
          <img src="@/assets/money.png" alt="" />
          <p>{{ match.stats[0].stat }} {{ match.stats[0].stat_text }}</p>
        </div>
        <div class="stat">
          <img src="@/assets/case.png" alt="" />
          <p>{{ match.stats[1].stat }} {{ match.stats[1].stat_text }}</p>
        </div>
      </div>
      <div class="my-3">
        <wes-button variant="secondary" size="default" @click="learnMore(match.url)"> Learn More</wes-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useQuizStore } from "@/stores/quiz_store";
import { useGtm } from "@gtm-support/vue-gtm";
const store = useQuizStore();
const props = defineProps(["match", "percent", "rank"]);
const gtm = useGtm();

const learnMore = (url) => {
  if (process.env.NODE_ENV === "production") {
    gtm.trackEvent({
      event: "eventTracker",
      category: "Outbound Link Clicks",
      action: "Learn More",
      label: url
    });
  }
  window.open(url, "_blank");
};
</script>

<style scoped></style>
