<template>
  <div style="padding-bottom: 40px">
    <div class="container" grid="align-center" axis="vertical" flow="wrap">
      <div size="1:1 1:2@medium 3:5@large" style="padding-right: 25px">
        <h1 class="for-mobile-large" type-style="display-2" style="padding-bottom: 5px !important">Your Results Are In!</h1>
        <p type-style="body-2" class="center-on-mobile" style="padding-bottom: 20px">
          Based on the answers you’ve shared,<br />we’ve matched you with the following career paths.
        </p>
        <!--        <a href="/">-->
        <!--          <wes-button variant="primary" size="default"> Home</wes-button>-->
        <!--        </a>-->
      </div>
      <div size="1:1 1:2@medium 2:5@large" class="hide-on-mobile">
        <img
          class="move-up"
          src="@/assets/results-hero.png"
          alt="Astro in Trailhead hat with hands up at the top of a trail celebrating."
        />
      </div>
    </div>

    <div class="container">
      <best-match-card :match="store.score_sorted[0]"></best-match-card>

      <h2 type-style="display-3" style="font-size: 24px">Explore your other career options</h2>

      <div grid="align-center" axis="vertical" flow="wrap" gap="large">
        <div size="1:1 1:2@medium 1:3@x-large">
          <secondary-match-card :match="store.score_sorted[1]" :percent="store.second_percent" rank="2"></secondary-match-card>
        </div>
        <div size="1:1 1:2@medium 1:3@x-large">
          <secondary-match-card :match="store.score_sorted[2]" :percent="store.third_percent" rank="3"></secondary-match-card>
        </div>
        <div class="download-card" size="1:1 1:1@medium 1:3@x-large" style="align-self: stretch">
          <h2 type-style="display-3" style="font-size: 2rem; text-align: center">Download and Share</h2>
          <p type-style="body-1" style="padding: 0 0 25px">Save your top career matches and share your results with your network.</p>
          <wes-button variant="primary" size="default" fluid="true" @click="downloadResults()"> Download Results</wes-button>
          <!-- <p style="font-size: 10px">{{ store.share_url }}</p> -->
          <div class="social-buttons hide-on-mobile-flex">
            <!-- <a href="https://www.facebook.com/sharer/sharer.php?u=trailblazer-career-match.herokuapp.com/results" target="_blank">-->
            <!-- <wes-button variant="secondary" size="default"> Facebook</wes-button>-->
            <!-- </a>-->
            <ShareNetwork
              network="facebook"
              :url="store.share_url"
              title="Trailblazer Career Match"
              hashtags="Trailhead,TrailblazerCommunity"
              quote="Identify your career matches in the Salesforce ecosystem - unlock your path to professional success with @Trailhead! Take the free quiz #TrailblazerCommunity at trailhead.com/career-match"
              :popup="{ width: 700, height: 500 }"
            >
              <wes-button variant="secondary" size="default"> Facebook</wes-button>
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="store.share_url"
              title="Trailblazer Career Match"
              hashtags="Trailhead,TrailblazerCommunity"
              :popup="{ width: 700, height: 650 }"
            >
              <wes-button variant="secondary" size="default"> Twitter</wes-button>
            </ShareNetwork>
            <ShareNetwork
              network="linkedin"
              :url="store.share_url"
              title="Trailblazer Career Match"
              hashtags="Trailhead,TrailblazerCommunity"
              :popup="{ width: 700, height: 650 }"
            >
              <wes-button variant="secondary" size="default"> LinkedIn</wes-button>
            </ShareNetwork>
          </div>
          <div class="social-buttons hide-on-desktop-flex">
            <ShareNetwork
              network="facebook"
              :url="store.share_url"
              title="Trailblazer Career Match"
              hashtags="Trailhead,TrailblazerCommunity"
              quote="Identify your career matches in the Salesforce ecosystem - unlock your path to professional success with @Trailhead! Take the free quiz #TrailblazerCommunity at trailhead.com/career-match"
              :popup="{ width: 700, height: 500 }"
            >
              <wes-button variant="secondary" size="small"> Facebook</wes-button>
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="store.share_url"
              title="Trailblazer Career Match"
              hashtags="Trailhead,TrailblazerCommunity"
              :popup="{ width: 700, height: 650 }"
            >
              <wes-button variant="secondary" size="small"> Twitter</wes-button>
            </ShareNetwork>
            <ShareNetwork
              network="linkedin"
              :url="store.share_url"
              title="Trailblazer Career Match"
              hashtags="Trailhead,TrailblazerCommunity"
              :popup="{ width: 700, height: 650 }"
            >
              <wes-button variant="secondary" size="small"> LinkedIn</wes-button>
            </ShareNetwork>
          </div>
          <a
            href="https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000001omnCAA?tab=discussion&sort=LAST_MODIFIED_DATE_DESC"
            target="_blank"
          >
            <wes-button variant="secondary" size="default" fluid="true"> Share on Trailblazer Community</wes-button>
          </a>
        </div>
      </div>
    </div>
    <!-- <scorecard></scorecard> -->
  </div>
</template>

<script setup>
// import { useCookies } from "vue3-cookies";
import WESBadge from "@salesforce-ux/wes-badge/dist/badge";
import { useQuizStore } from "@/stores/quiz_store";
import { saveAs } from "file-saver";
import BestMatchCard from "../BestMatchCard.vue";
import SecondaryMatchCard from "../SecondaryMatchCard.vue";
// import Scorecard from "../Scorecard.vue";
import WESButton from "@salesforce-ux/wes-button/dist/button";
import { useGtm } from "@gtm-support/vue-gtm";
const gtm = useGtm();
window.customElements.define("wes-button", WESButton);
window.customElements.define("wes-badge", WESBadge);

const store = useQuizStore();
// const { cookies } = useCookies();

const downloadResults = () => {
  const url = `/images/downloads/${store.score_sorted[0].download}.jpg`;
  if (process.env.NODE_ENV === "production") {
    gtm.trackEvent({
      event: "eventTracker",
      category: "Downloads",
      action: "Image",
      label: `my-career-match-${store.score_sorted[0].download}.jpg`
    });
  }
  saveAs(url, `my-career-match-${store.score_sorted[0].download}.jpg`);
};

const checkQuizStatus = () => {
  if (store.quiz_started === false) {
    window.location.href = "/quiz-values";
  } else if (store.question2_started === false) {
    window.location.href = "/quiz-purpose";
  } else if (store.question3_started === false) {
    window.location.href = "/quiz-preferences";
  }
};
checkQuizStatus();
</script>

<style scoped></style>
