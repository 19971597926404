import "./main.scss";
// import "@popperjs/core";
// import "bootstrap"

import { createApp } from "vue";
import { createPinia } from "pinia";
import { createGtm } from "@gtm-support/vue-gtm";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueSocialSharing from "vue-social-sharing";
// import VueHead from 'vue-head';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
import Home from "../components/views/Home.vue";
import Quiz from "../components/views/Quiz.vue";
import QuizValues from "../components/views/QuizValues.vue";
import QuizPurpose from "../components/views/QuizPurpose.vue";
import QuizPreferences from "../components/views/QuizPreferences.vue";
import Results from "../components/views/Results.vue";
import SocialSharing from "../components/SocialSharing.vue";

if (document.querySelector("#home")) {
  const home = createApp(Home);
  home.use(pinia);
  home.use(
    createGtm({
      id: "GTM-K4S25JQ",
      compatibility: false,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
  home.mount("#home");
}

if (document.querySelector("#quiz")) {
  const home = createApp(Quiz);
  home.use(pinia);
  home.use(
    createGtm({
      id: "GTM-K4S25JQ",
      compatibility: false,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
  home.mount("#quiz");
}

if (document.querySelector("#quiz-values")) {
  const home = createApp(QuizValues);
  home.use(pinia);
  home.use(
    createGtm({
      id: "GTM-K4S25JQ",
      compatibility: false,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
  home.mount("#quiz-values");
}

if (document.querySelector("#quiz-purpose")) {
  const home = createApp(QuizPurpose);
  home.use(pinia);
  home.use(
    createGtm({
      id: "GTM-K4S25JQ",
      compatibility: false,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
  home.mount("#quiz-purpose");
}

if (document.querySelector("#quiz-preferences")) {
  const home = createApp(QuizPreferences);
  home.use(pinia);
  home.use(
    createGtm({
      id: "GTM-K4S25JQ",
      compatibility: false,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
  home.mount("#quiz-preferences");
}

if (document.querySelector("#results")) {
  const home = createApp(Results);
  home.use(pinia);
  home.use(VueSocialSharing);
  // home.use(VueHead);
  home.use(
    createGtm({
      id: "GTM-K4S25JQ",
      compatibility: false,
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  );
  home.mount("#results");
}

if (document.querySelector("#social-sharing")) {
  const home = createApp(SocialSharing);
  // home.use(pinia);
  home.use(VueSocialSharing);
  // home.use(VueHead);
  // home.use(
  //   createGtm({
  //     id: "GTM-K4S25JQ",
  //     compatibility: false,
  //     enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  //     debug: true, // Whether or not display console logs debugs (optional)
  //     loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  //     trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  //   })
  // );
  home.mount("#social-sharing");
}
