<template>
  <div>
    <div class="container" grid="align-center" axis="vertical" flow="wrap">
      <div size="1:1 1:2@medium 2:4@large" style="padding-right: 25px">
        <h1 class="for-mobile-large" type-style="display-1">Find the Perfect Role for You</h1>
        <p type-style="body-3" class="mb-5">
          Whether you’re new to tech or looking to level up your skills, anyone can succeed in the Salesforce ecosystem.
        </p>
<!--        <a href="/">-->
<!--          <wes-button variant="primary" size="default"> Home</wes-button>-->
<!--        </a>-->
      </div>
      <div size="1:1 1:2@medium 2:4@large">
        <img src="@/assets/quiz-values-header.png"
             alt="Astro in Trailhead hat with walking stick at the beginning of a trail."/>
      </div>
    </div>

    <ProgressBar current-step="1"/>

    <div class="container">
      <div style="max-width: 800px; margin: 0 auto;">
        <h2 type-style="display-3" class="text-center">What values do you prioritize in your work?</h2>
        <p type-style="body-3" class="small text-center">
          Drag and drop the items with most important on top, least important on the bottom.
        </p>
        <!--  <p>{{ assistiveText }}</p> -->
        <Draggable v-model="store.answers1" @change="store.calculateScore_q1()" :animation="100" item-key="id" tag="ol"
                   role="listbox">
          <template #item="{ element, index }">
            <li class="drag-item quiz-list__item">
              <p style="color: #DDD; font-size: 24px">{{ index + 1 }}</p>
              <p>{{ element.text }}</p>
              <img src="@/assets/handle.png" alt="draggable handle">
            </li>
          </template>
        </Draggable>
        <div style="text-align: center">
          <wes-button variant="primary" size="default" @click.prevent="onToStepTwo()">Next</wes-button>
        </div>
      </div>
    </div>
    <!-- <scorecard></scorecard> -->
    <div class="quiz-footer"><img src="@/assets/quiz-footer.png" alt=""></div>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import QuizListItem from "../QuizListItem.vue";
import "@salesforce-ux/wes-styling-hooks/dist/hooks.custom-props.css";
import WESButton from "@salesforce-ux/wes-button/dist/button";
import ProgressBar from "../ProgressBar.vue";
import { useQuizStore } from "@/stores/quiz_store";
import Draggable from "vuedraggable";
// import Scorecard from "../Scorecard.vue";
const slots = ref([]);
const store = useQuizStore();
window.customElements.define("wes-button", WESButton);
// const stepCheck = () => {
//   if (store.step === 4) {
//     window.location.href = "/results";
//   }
// };
// stepCheck();

// const score_sorted = computed(() => store.quiz_score.sort((a, b) => (a.score < b.score ? 1 : -1)));

const onToStepTwo = () => {
  store.calculateScore_q1();
  store.step = 2;
  window.location.href = "/quiz-purpose";
  // store.calculateScore("values", 1);
};

const randomize = async () => {
  // console.log(store.answers1)
  if (!store.quiz_started) {
    for (let i = 0; i < store.answers1.length; i++) {
      slots.value = store.answers1.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
    }
    store.quiz_started = true;
  }
};
randomize(store.answers1);
store.calculateScore_q1();

// const assistiveText = ref("");
</script>

<style scoped lang="scss"></style>
