<template>
  <div>
    <div style="background-color: #f1faff; padding-bottom: 50px">
      <div class="container" grid="align-center" axis="vertical" flow="wrap">
        <div size="1:1 1:2@medium 2:4@large" style="padding-right: 25px">
          <h1 class="for-mobile" type-style="display-1" style="padding-bottom: 0 !important; text-align: left">Find Your Career
            Path</h1>
          <p type-style="body-3" class="mb-5">
            Identify your career matches in the Salesforce ecosystem - take our free quiz and unlock your path to professional success!
          </p>
          <wes-button @click="triggerEvent" variant="primary" size="default"> Take the Quiz</wes-button>
        </div>
        <div size="1:1 1:2@medium 2:4@large">
<!--          <img-->
<!--            class="move-up desktop-img"-->
<!--            src="@/assets/home-main-img.png"-->
<!--            alt="Photo of laptop with Salesforce Admin result on the page, a mobile device with one of the quiz questions shown, Business Analyst career option on the side"-->
<!--          />-->
          <img
            class="move-up desktop-img"
            :src="MainImage"
            alt="Photo of laptop with Salesforce Admin result on the page, a mobile device with one of the quiz questions shown, Business Analyst career option on the side"
          />
          <img
            class="move-up mobile-img"
            :src="MainImageMobile"
            alt="Photo of laptop with Salesforce Admin result on the page, a mobile device with one of the quiz questions shown, Business Analyst career option on the side"
          />
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; align-items: center; margin-top: 35px; margin-bottom: 75px" class="container">
      <h2 type-style="display-3">Be A Trailblazer</h2>
      <p type-style="body-2" style="max-width: 500px; text-align: center">
        With the demand for Salesforce-skilled talent skyrocketing, there’s never been a better time to join the Salesforce ecosystem.
      </p>
      <div class="icon-block">
        <div style="display: flex; flex-direction: column; align-items: center">
          <img src="@/assets/home-skills01.png" alt="Illustration of rocket ship" class="icon" />
          <h2 type-style="display-4" style="padding-bottom: 0 !important">9.3 Million</h2>
          <p type-style="body-2" style="padding-top: 0 !important">Jobs by 2026*</p>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <img src="@/assets/home-skills02.png" alt="Illustration of briefcase" class="icon" />
          <h2 type-style="display-4" style="padding-bottom: 0 !important">410,000+</h2>
          <p type-style="body-2" style="padding-top: 0 !important">Open Roles in 2021*</p>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <img src="@/assets/home-skills03.png" alt="Illustration of envelope filled with money" class="icon" />
          <h2 type-style="display-4" style="padding-bottom: 0 !important">$95k</h2>
          <p type-style="body-2" style="padding-top: 0 !important">Median Base Salary*</p>
        </div>
      </div>
    </div>

    <div style="padding: 75px 0; background: rgb(217, 242, 249)">
      <div class="container">
        <div grid="align-center" flow="wrap" axis="vertical" style="align-items: center">
          <div size="1:1 1:2@medium 2:5@large" style="text-align: center">
            <img :src="Astro" alt="Astro in Trailhead hat with walking stick." style="max-width: 350px" />
          </div>
          <div size="1:1 1:2@medium 2:5@large" style="padding-left: 32px">
            <h2 type-style="display-4">Discover Your Perfect Career Path</h2>
            <p type-style="body-3" class="mb-5" style="color: #032d60 !important">
              Whether you’re new to tech or looking to level up your skills, anyone can succeed in the Salesforce ecosystem.
            </p>
            <a href="/quiz-values">
              <wes-button variant="primary" size="default"> Get Started</wes-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import "@salesforce-ux/wes-styling-hooks/dist/hooks.custom-props.css";
import WESButton from "@salesforce-ux/wes-button/dist/button";
import WESImage from "@salesforce-ux/wes-image/dist/image";
import { useGtm } from "@gtm-support/vue-gtm";
import MainImage from "/assets/home-main-img.png?w=700&h=388&webp";
import MainImageMobile from "/assets/home-main-mobile.png?w=500&h=548&webp";
import Astro from "/assets/home-astro.png?w=500&h=338&webp";
// import { ref } from "vue";
// import Modal from "../Modal.vue";

window.customElements.define("wes-button", WESButton);
window.customElements.define("wes-image", WESImage);

// const showModal = ref(false);

const gtm = useGtm();
function triggerEvent() {
  if (process.env.NODE_ENV === "production") {
    gtm.trackEvent({
      event: "eventTracker",
      category: "Internal Link Clicks",
      action: "Take the Quiz",
      label: "/quiz-values"
    });
  }
  window.location.href = "/quiz-values";
}
</script>
