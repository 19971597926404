<template>
  <div>
    <div class="container" grid="align-center" axis="vertical" flow="wrap">
      <div size="1:1 1:2@medium 2:4@large" style="padding-right: 25px">
        <h1 class="for-mobile-large" type-style="display-1">Find the Perfect Role for You</h1>
        <!--        <p type-style="body-3" class="mb-5">-->
        <!--          Whether you’re new to tech or looking to level up your career, anyone can succeed with Salesforce.-->
        <!--        </p>-->
        <!--        <a href="/">-->
        <!--          <wes-button variant="primary" size="default"> Home</wes-button>-->
        <!--        </a>-->
      </div>
      <div size="1:1 1:2@medium 2:4@large" class="hide-on-mobile">
        <img src="@/assets/quiz-purpose-header.png" alt="Astro in Trailhead hat with walking stick in the middle of a trail." />
      </div>
    </div>

    <ProgressBar current-step="2" />

    <div class="container">
      <div style="max-width: 800px; margin: 0 auto">
        <h2 type-style="display-3" class="text-center">What work ignites your passion?</h2>
        <p type-style="body-3" class="small text-center">
          Drag and drop the items with most important on top, least important on the bottom.
        </p>
        <!--  <p>{{ assistiveText }}</p> -->
        <div v-if="store.answers1[0].id === 1">
          <Draggable
            v-model="store.adaptability_answers"
            @change="store.calculateScore_q2()"
            :animation="100"
            item-key="id"
            tag="ol"
            role="listbox"
          >
            <template #item="{ element, index }">
              <li class="drag-item quiz-list__item">
                <p style="color: #ddd; font-size: 24px">{{ index + 1 }}</p>
                <p>{{ element.text }}</p>
                <img src="@/assets/handle.png" alt="draggable handle" />
              </li>
            </template>
          </Draggable>
        </div>
        <div v-else-if="store.answers1[0].id === 2">
          <Draggable v-model="store.empathy_answers" @change="store.calculateScore_q2()" :animation="100" item-key="id" role="listbox">
            <template #item="{ element, index }">
              <li class="drag-item quiz-list__item">
                <p style="color: #ddd; font-size: 24px">{{ index + 1 }}</p>
                <p>{{ element.text }}</p>
                <img src="@/assets/handle.png" alt="draggable handle" />
              </li>
            </template>
          </Draggable>
        </div>
        <div v-else-if="store.answers1[0].id === 3">
          <Draggable
            v-model="store.innovation_answers"
            @change="store.calculateScore_q2()"
            :animation="100"
            item-key="id"
            tag="ol"
            role="listbox"
          >
            <template #item="{ element, index }">
              <li class="drag-item quiz-list__item">
                <p style="color: #ddd; font-size: 24px">{{ index + 1 }}</p>
                <p>{{ element.text }}</p>
                <img src="@/assets/handle.png" alt="draggable handle" />
              </li>
            </template>
          </Draggable>
        </div>
        <div v-else-if="store.answers1[0].id === 4">
          <Draggable v-model="store.efficiency_answers" @change="store.calculateScore_q2()" :animation="100" item-key="id" role="listbox">
            <template #item="{ element, index }">
              <li class="drag-item quiz-list__item">
                <p style="color: #ddd; font-size: 24px">{{ index + 1 }}</p>
                <p>{{ element.text }}</p>
                <img src="@/assets/handle.png" alt="draggable handle" />
              </li>
            </template>
          </Draggable>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <wes-button variant="tertiary" size="default" @click.prevent="returnToStepOne()">Back</wes-button>
      <wes-button variant="primary" size="default" @click.prevent="onToStepThree()">Next</wes-button>
    </div>
    <div class="quiz-footer"><img src="@/assets/quiz-footer.png" alt="" /></div>

    <!-- <scorecard></scorecard> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
// import QuizListItem from "../QuizListItem.vue";
import "@salesforce-ux/wes-styling-hooks/dist/hooks.custom-props.css";
import ProgressBar from "../ProgressBar.vue";
import { useQuizStore } from "@/stores/quiz_store";
import Draggable from "vuedraggable";
// import Scorecard from "../Scorecard.vue";
import WESButton from "@salesforce-ux/wes-button/dist/button";

window.customElements.define("wes-button", WESButton);
const slots = ref([]);
const store = useQuizStore();

const onToStepThree = () => {
  store.calculateScore_q2();
  store.step = 3;
  window.location.href = "/quiz-preferences";
};

const returnToStepOne = () => {
  store.step = 2;
  window.location.href = "/quiz-values";
};

const randomize = async (group) => {
  // console.log(store.answers1)
  if (!store.question2_started) {
    if (group === "adaptability_answers") {
      for (let i = 0; i < store.adaptability_answers.length; i++) {
        slots.value = store.adaptability_answers.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
      }
    } else if (group === "empathy_answers") {
      for (let i = 0; i < store.empathy_answers.length; i++) {
        slots.value = store.empathy_answers.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
      }
    } else if (group === "innovation_answers") {
      for (let i = 0; i < store.innovation_answers.length; i++) {
        slots.value = store.innovation_answers.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
      }
    } else if (group === "efficiency_answers") {
      for (let i = 0; i < store.efficiency_answers.length; i++) {
        slots.value = store.efficiency_answers.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
      }
    }
    store.question2_started = true;
  }
};

if (store.answers1[0].id === 1) {
  randomize("adaptability_answers");
} else if (store.answers1[0].id === 2) {
  randomize("empathy_answers");
} else if (store.answers1[0].id === 3) {
  randomize("innovation_answers");
} else if (store.answers1[0].id === 4) {
  randomize("efficiency_answers");
}
store.calculateScore_q2();

const checkQuizStatus = () => {
  if (store.quiz_started === false) {
    window.location.href = "/quiz-values";
  } else if (store.question2_started === false) {
    window.location.href = "/quiz-purpose";
  }
};
checkQuizStatus();
</script>

<style scoped lang="scss"></style>
