<template>
  <div class="best-match-card" grid="" axis="vertical" flow="wrap">
    <div size="1:1 1:4@medium 1:4@large" class="img-div">
      <img :src="match.image" alt="Trailblazers" />
    </div>
    <div size="1:1 3:4@medium 3:4@large" style="padding: 0 25px">
      <!--      <wes-badge>-->
      <!--        <slot name="start"><img class="badge-icon" src="@/assets/locker_service_console.png" alt="" /></slot>-->
      <!--        Best Match-->
      <!--      </wes-badge>-->
      <!--      <wes-badge type="default" value="green">{{ store.top_percent }}% Match</wes-badge>-->

      <div class="badges">
        <div class="badge badge-grey"><img class="badge-icon" src="@/assets/locker_service_console.png" alt="" /> Best Match</div>
        <div class="badge badge-green">Career Match #1</div>
        <!--        <div class="badge badge-green">{{ store.top_percent }}% Match</div>-->
      </div>

      <h1 class="for-mobile" type-style="display-3" style="padding-bottom: 0 !important">{{ match.main_title }}</h1>
      <div class="my-5 hide-on-desktop" style="text-align: center">
        <wes-button variant="primary" size="default" @click="getStarted()">Learn More</wes-button>
      </div>
      <p type-style="body-2" class="mb-5 hide-on-mobile">
        {{ match.desktop_copy }}
      </p>
      <p type-style="body-3" class="my-5 hide-on-desktop text-center" style="margin-bottom: 20px">
        {{ match.mobile_copy }}
      </p>
      <div class="stat-block">
        <div class="stat">
          <img src="@/assets/money.png" alt="" />
          <p>{{ match.stats[0].stat }} {{ match.stats[0].stat_text }}</p>
        </div>
        <div class="stat">
          <img src="@/assets/case.png" alt="" />
          <p>{{ match.stats[1].stat }} {{ match.stats[1].stat_text }}</p>
        </div>
        <div class="stat hide-stat-on-mobile">
          <img src="@/assets/trending.png" alt="" />
          <p>{{ match.stats[2].stat }} {{ match.stats[2].stat_text }}</p>
        </div>
      </div>
      <div class="my-5 hide-on-mobile">
        <wes-button variant="primary" size="default" @click="getStarted()">Get Started</wes-button>
      </div>
      <p type-style="body-3"><b>Top Skills In This Role:</b></p>
      <p type-style="body-3" class="hide-on-mobile mt-1" style="color: #585858">
        {{ match.top_skills }}
      </p>
      <ul class="skills-list-mobile hide-on-desktop">
        <li v-for="skill in match.top_skills_mobile" :key="skill">{{ skill }}</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { useQuizStore } from "@/stores/quiz_store";
import { useGtm } from "@gtm-support/vue-gtm";
const store = useQuizStore();
// eslint-disable-next-line no-unused-vars
const props = defineProps(["match"]);
const gtm = useGtm();

const getStarted = () => {
  store.link_clicked(store.random_url_best_match[0]);
  if (process.env.NODE_ENV === "production") {
    gtm.trackEvent({
      event: "eventTracker",
      category: "Outbound Link Clicks",
      action: "Get Started",
      label: store.random_url_best_match[0]
    });
  }
  window.open(store.random_url_best_match[0], "_blank");
};
</script>

<style scoped></style>
