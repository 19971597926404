<template>
  <div>
    <div class="container" grid="align-center" axis="vertical" flow="wrap">
      <div size="1:1 1:2@medium 2:4@large" style="padding-right: 25px">
        <h1 type-style="display-1">Let's get started</h1>
        <p type-style="body-3" class="mb-5">
          Whether you’re new to tech or looking to level up your career, anyone can succeed with Salesforce.
        </p>
<!--        <a href="/">-->
<!--          <wes-button variant="primary" size="default"> Home</wes-button>-->
<!--        </a>-->
      </div>
      <div size="1:1 1:2@medium 2:4@large">
        <img style="margin-top: -30px" src="@/assets/quiz-head-illustration.png" alt="Trailblazers"/>
      </div>
    </div>

    <ProgressBar/>

    <div class="container" v-if="store.step === 1">
      <div style="max-width: 800px; margin: 0 auto;">
        <h2 type-style="display-3" class="text-center">Which value is most important to have at the heart of your work?</h2>
        <p type-style="body-3" class="small text-center">
          Drag and drop the items with most important on top, least important on the bottom.
        </p>
        <!--  <p>{{ assistiveText }}</p> -->
        <Draggable v-model="store.answers1" :animation="100" item-key="id" tag="ol" role="listbox">
          <template #item="{ element }">
            <li class="drag-item quiz-list__item">{{ element.text }}</li>
          </template>
        </Draggable>
        <div style="text-align: center">
          <wes-button variant="primary" size="default" @click.prevent="onToStepTwo()"> On to step two</wes-button>
        </div>
      </div>
    </div>

    <div class="container" v-if="store.step === 2">
      <div v-if="store.answers1[0].id === 1">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.continuous_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.continuous_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.short_text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> On to the last step</wes-button>
          </div>
        </div>
      </div>
      <div v-else-if="store.answers1[0].id === 2">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.customerfocus_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.customerfocus_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.short_text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> On to the last step</wes-button>
          </div>
        </div>
      </div>
      <div v-else-if="store.answers1[0].id === 3">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.innovation_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.innovation_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.short_text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> On to the last step</wes-button>
          </div>
        </div>
      </div>
      <div v-else-if="store.answers1[0].id === 4">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.integrity_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.integrity_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.short_text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> On to the last step</wes-button>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="store.step === 3">
      <div v-if="store.answers1[1].id === 1">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.continuous_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.continuous_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> Show me the results!</wes-button>
          </div>
        </div>
      </div>
      <div v-else-if="store.answers1[1].id === 2">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.customerfocus_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.customerfocus_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> Show me the results!</wes-button>
          </div>
        </div>
      </div>
      <div v-else-if="store.answers1[1].id === 3">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.innovation_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.innovation_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="onToStepThree()"> Show me the results!</wes-button>
          </div>
        </div>
      </div>
      <div v-else-if="store.answers1[1].id === 4">
        <div style="max-width: 800px; margin: 0 auto;">
          <h2 type-style="display-3" class="text-center">{{ store.integrity_q }}</h2>
          <p type-style="body-3" class="small text-center">
            Drag and drop the items with most important on top, least important on the bottom.
          </p>
          <!--  <p>{{ assistiveText }}</p> -->
          <Draggable v-model="store.integrity_answers" :animation="100" item-key="id" tag="ol" role="listbox">
            <template #item="{ element }">
              <li class="drag-item quiz-list__item">{{ element.text }}</li>
            </template>
          </Draggable>
          <div style="text-align: center">
            <wes-button variant="primary" size="default" @click.prevent="completeQuiz()"> Show me the results!</wes-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center" style="margin-top: 20px;" v-if="store.step === 4">
      <wes-button variant="primary" size="default" @click.prevent="restartQuiz()"> Restart </wes-button>
    </div>
    <div class="container">
      <pre>
        <ul class="text-center">
          <li v-for="score in store.score_sorted">{{ `${score.title}: ${score.score}` }}</li>
        </ul>
      </pre>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
// import QuizListItem from "../QuizListItem.vue";
import "@salesforce-ux/wes-styling-hooks/dist/hooks.custom-props.css";
import ProgressBar from "../ProgressBar.vue";
import { useQuizStore } from "@/stores/quiz_store";
import Draggable from "vuedraggable";

const slots = ref([]);
const store = useQuizStore();
// const stepCheck = () => {
//   if (store.step === 4) {
//     window.location.href = "/results";
//   }
// };
// stepCheck();

// const score_sorted = computed(() => store.quiz_score.sort((a, b) => (a.score < b.score ? 1 : -1)));

const onToStepTwo = () => {
  store.calculateScore(1);
};

const onToStepThree = () => {
  store.calculateScore(2);
};

const completeQuiz = () => {
  store.calculateScore(3);
};

const restartQuiz = () => {
  store.step = 1;
  store.quiz_score.forEach((e) => {
    e.score = 0;
  });
};

const randomize = async () => {
  // console.log(store.answers1)
  for (let i = 0; i < store.answers1.length; i++) {
    slots.value = store.answers1.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
  }
};
randomize(store.answers1);

// const assistiveText = ref("");
</script>

<style scoped lang="scss"></style>
