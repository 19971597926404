<template>
  <div style="padding: 32px 0; background: #F3F7FF">
    <div class="container">
      <p v-if="currentStep == 1" type-style="body-3" class="text-center" style="padding-bottom: 25px; color: #014486">
        We’ll guide you through three questions on your discovery journey.
      </p>
      <p v-else-if="currentStep == 2" type-style="body-3" class="text-center" style="padding-bottom: 25px; color: #014486">
        Now let’s learn more about what motivates you.
      </p>
      <p v-else-if="currentStep == 3" type-style="body-3" class="text-center" style="padding-bottom: 25px; color: #014486">
        Finally, let’s explore your work preferences.
      </p>
      <div class="nav-progress">
        <div class="progress-item complete">
          <span v-if="currentStep >= 2"><span class="circle">1</span> <a href="/quiz-values">Values</a></span>
          <span v-else><span class="circle">1</span> Values</span>
          <div class="arrow-wrapper">
            <div class="arrow-cover">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
        <div class="progress-item" :class="currentStep >= 2 ? 'complete' : ''">
          <span v-if="currentStep >= 3"><span class="circle">2</span> <a href="/quiz-purpose">Purpose</a></span>
          <span v-else><span class="circle">2</span> Purpose</span>
          <div class="arrow-wrapper">
            <div class="arrow-cover">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
        <div class="progress-item" :class="currentStep >= 3 ? 'complete' : ''">
          <span class="circle">3</span> Preferences
          <div class="arrow-wrapper">
            <div class="arrow-cover">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useQuizStore } from "@/stores/quiz_store";
const store = useQuizStore();
const props = defineProps(["currentStep"]);
</script>
