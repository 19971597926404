<template>
  <div>
    <ShareNetwork
      network="facebook"
      url="https://trailblazer-career-match.herokuapp.com/"
      title="Trailblazer Career Match"
      hashtags="Trailhead,TrailblazerCommunity"
      :popup="{ width: 700, height: 500 }"
      quote="Identify your career matches in the Salesforce ecosystem - unlock your path to professional success with @Trailhead! Take the free quiz #TrailblazerCommunity at trailhead.com/career-match"
    >
      <img src="@/assets/Facebook.png" alt="" class="icon-sm" />
    </ShareNetwork>
    <!--    <ShareNetwork-->
    <!--      network="twitter"-->
    <!--      url="https://trailblazer-career-match.herokuapp.com/"-->
    <!--      title="Trailblazer Career Match"-->
    <!--      hashtags="Trailhead,TrailblazerCommunity"-->
    <!--      :popup="{ width: 700, height: 650 }"-->
    <!--    >-->
    <a href="https://ctt.ac/V0TXN" target="_blank">
      <img src="@/assets/Twitter.png" alt="" class="icon-sm" />
    </a>
    <!--    </ShareNetwork>-->
    <!-- <img src="@/assets/Instagram.png" alt="" class="icon-sm" /> -->
    <ShareNetwork
      network="linkedin"
      url="https://trailblazer-career-match.herokuapp.com/"
      title="Trailblazer Career Match"
      hashtags="Trailhead,TrailblazerCommunity"
      :popup="{ width: 700, height: 650 }"
    >
      <img src="@/assets/Linkedin.png" alt="" class="icon-sm" />
    </ShareNetwork>
  </div>
</template>

<script setup></script>

<style scoped></style>
