import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";
import axios from "axios";
const { cookies } = useCookies();

export const useQuizStore = defineStore("quiz_store", {
  state: () => {
    return {
      step: 1,
      quiz_started: false,
      question2_started: false,
      question3_started: false,
      quiz_submitted: false,
      quiz_id: 0,
      show_panel: true,
      tiebreaker_calc: false,
      quiz_score: [
        {
          title: "Admin",
          main_title: "Salesforce Administrator",
          priority: 7,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/admin",
          url2: "https://trailhead.salesforce.com/content/learn/modules/salesforce-marketer-quick-look/get-to-know-the-marketer-role",
          desktop_copy:
            "Salesforce Admins solve business problems by customizing the Salesforce Platform. They build, configure," +
            " and automate technology solutions to deliver business value. Core tasks include supporting users, managing data, managing security, and driving actionable analytics.",
          mobile_copy:
            "A Salesforce Administrator solves complex business problems by customizing the Salesforce Platform. They build," +
            " configure, and automate technology solutions for organizations around the world.",
          top_skills: "Process Automation, Data Analysis, Data Management, Security Management, Design Mindset, Communication",
          top_skills_mobile: [
            "Process Automation",
            "Data Analysis",
            "Data Management",
            "Security Management",
            "Design Mindset",
            "Communication"
          ],
          download: "salesforce-administrator",
          image: "/images/sf-admin.png",
          image_alt: "Headshot of a female Salesforce Admin, Karmel James, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$85,900", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "12,065", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "33%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Architect",
          main_title: "Salesforce Architect",
          priority: 9,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/architect",
          url2: "https://trailhead.salesforce.com/content/learn/modules/salesforce-architect-role-quick-look/salesforce-architect-role-quick-look-1",
          desktop_copy:
            "Salesforce Architects design, build, and deliver solutions businesses need to thrive in a demanding economy. They don't need an engineering background, but they have deep technical knowledge and are the lead technical resource for delivery teams and project stakeholders.",
          mobile_copy:
            "A Salesforce Architect designs, builds, and delivers solutions businesses need to thrive in a demanding economy. They’re the lead technical resource for delivery teams and project stakeholders.",
          top_skills: "Application Management, Cloud Computing, Teaching, Software Development, Quality Assurance, Marketing Software",
          top_skills_mobile: [
            "App Management",
            "Cloud Computing",
            "Teaching",
            "App Development",
            "Quality Assurance",
            "Marketing Software"
          ],
          image: "/images/sf-architect.png",
          download: "salesforce-architect",
          image_alt: "Headshot of a male Salesforce Architect, Aldo Fernandez, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$140,400", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "16,700", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "44%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Business Analyst",
          main_title: "Business Analyst",
          priority: 6,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/business-analyst",
          url2: "https://trailhead.salesforce.com/content/learn/modules/salesforce-business-analyst-quick-look/learn-about-the-salesforce-business-analyst-role",
          desktop_copy:
            "Business Analysts focus on business-improvements and project successes. They identify needs and value for the customer environment while communicating between IT and business stakeholders to ensure all involved work together to achieve the best results.",
          mobile_copy:
            "A business analyst is a project-based, business role. Business analysts guide businesses to improve processes and efficiency in Salesforce by evaluating problems and making actionable recommendations.",
          top_skills: "Agile Methodology, Communication, Business Analysis, Quality Assurance, Designer Mindset, Process Optimization",
          top_skills_mobile: [
            "Agile Methodology",
            "Communication",
            "Business Analysis",
            "Quality Assurance",
            "Designer Mindset",
            "Process Development"
          ],
          image: "/images/business-analyst.png",
          download: "business-analyst",
          image_alt: "Headshot of a male Salesforce Business Analyst, Nick Lindberg, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$93,600", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "34,870", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "41%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Consultant",
          main_title: "Salesforce Consultant",
          priority: 4,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/consultant",
          url2: "https://trailhead.salesforce.com/content/learn/modules/get-to-know-consultant-role/get-to-know-the-consultant-role",
          desktop_copy:
            "A Salesforce Consultant is a curious problem solver. They confidently bridge the gap between a customer’s challenges and their goals by creating a plan that maps to results and figuring out what Salesforce tools the customer needs to succeed.",
          mobile_copy:
            "A Salesforce Consultant is a curious problem solver. They confidently bridge the gap between a customer’s challenges and their goals while figuring out what tools the customer needs to succeed.",
          top_skills:
            "Client Relationship, Process Automation, Business Analysis, Product Management, Quality Assurance, Software Development",
          top_skills_mobile: [
            "Client Relationship",
            "Process Automation",
            "Business Analysis",
            "Product Management",
            "Quality Assurance",
            "App Development"
          ],
          download: "salesforce-consultant",
          image: "/images/sf-consultant.png",
          image_alt: "Headshot of a female Salesforce Consultant, Amanda Ouedraogo, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$103,900", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "31,120", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "22%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Cybersecurity",
          main_title: "Cybersecurity Professional",
          priority: 10,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/cybersecurity",
          url2: "https://trailhead.salesforce.com/content/learn/modules/cybersecurity-role-quick-look/get-to-know-the-cybersecurity-role",
          desktop_copy:
            "Cybersecurity professionals are problem-solvers who protect networks and systems. They identify security threats and find ways to mitigate cyber risk. They specialize in information security, risk and compliance, training, communication, and project management.",
          mobile_copy:
            "Cybersecurity professionals manage tools and techniques to protect technology devices and the data they contain. They specialize in risk management and threat analysis to protect important information.",
          top_skills: "Data Security, Security Operations, Risk Management, Problem Solving, Communication, Project Management",
          top_skills_mobile: [
            "Data Security",
            "Security Operations",
            "Risk Management",
            "Problem Solving",
            "Communication",
            "Project Management"
          ],
          download: "cybersecurity-professional",
          image: "/images/cyber-pro.png",
          image_alt: "Headshot of a female Salesforce Cybersecurity professional, Mariel Townsend, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$106,800", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "714,600", stat_text: "Open Jobs**" },
            { stat_type: "growth", stat: "35%", stat_text: "Projected Growth***" }
          ]
        },
        {
          title: "Designer",
          main_title: "Salesforce Designer",
          priority: 3,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/designer",
          url2: "https://trailhead.salesforce.com/content/learn/modules/salesforce-designer-quick-look/get-to-know-the-salesforce-designer-role",
          desktop_copy:
            "Salesforce Designers create intuitive experiences that help end users be successful. They optimize flows, interactions, and visual interfaces with pixel-level perfection. They map ecosystems, align stakeholders, and have people at the heart of everything they do.",
          mobile_copy:
            "A Salesforce Designer optimizes flows, interactions, and visual interfaces for intuitive, human-centered experiences built on the Salesforce Platform. People are at the heart of everything they do.",
          top_skills: "Communication, Product Development, Research, Agile Methodology, Design Mindset, UX Design",
          top_skills_mobile: ["Communication", "Product Development", "Research", "Agile Methodology", "Design Mindset", "UX Design"],
          download: "salesforce-designer",
          image: "/images/sf-designer.png",
          image_alt: "Headshot of a female Salesforce Developer, Asterisk Loftis, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$122,700", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "4,750", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "52%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Developer",
          main_title: "Salesforce Developer",
          priority: 8,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/developer",
          url2: "https://trailhead.salesforce.com/content/learn/modules/salesforce-developer-quick-look/get-to-know-the-salesforce-developer-role",
          desktop_copy:
            "Salesforce developers build applications with no-code and code, without compromise. As Salesforce technology API integration and code experts, they deliver innovative customer experiences and success for their users, teams, and organizations.",
          mobile_copy:
            "A Salesforce Developer builds applications with no-code and code Salesforce technologies to build custom business apps and solutions that deliver innovative customer experiences at scale.",
          top_skills: "Software Development, App Management, Agile Methodology, Process Automation, Problem Solving, UI Design",
          top_skills_mobile: [
            "Software Development",
            "App Management",
            "Agile Methodology",
            "Process Automation",
            "Problem Solving",
            "UI Design"
          ],
          download: "salesforce-developer",
          image: "/images/sf-developer.png",
          image_alt: "Headshot of a female Salesforce Developer, Sima Samara, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$120,000", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "52,270", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "46%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Marketer",
          main_title: "Marketer",
          priority: 5,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/marketer",
          url2: "https://trailhead.salesforce.com/content/learn/modules/salesforce-marketer-quick-look/get-to-know-the-marketer-role",
          desktop_copy:
            "Marketers are pros at building brand awareness, analyzing market trends, knowing buyers, generating leads, and making the most of innovations to stay ahead of the competition. They can specialize in anything from content to products to data analytics.",
          mobile_copy:
            "Marketing professionals are pros at building brand awareness, analyzing market trends, understanding buyers, generating leads, and making the most of innovations to stay ahead of the competition.",
          top_skills: "Marketing Strategy, Client Relationship, Communication, Sales Practices, Business Management, Digital Marketing",
          top_skills_mobile: [
            "Marketing Strategy",
            "Client Relationship",
            "Communication",
            "Sales Practices",
            "Business Management",
            "Digital Marketing"
          ],
          download: "marketer",
          image: "/images/marketer.png",
          image_alt: "Headshot of a female Salesforce Marketer, Rochelle Hinds, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$81,000", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "45,920", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "58%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Sales",
          main_title: "Sales Professional",
          priority: 2,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/sales",
          url2: "https://trailhead.salesforce.com/content/learn/modules/sales-role-quick-look/get-to-know-the-sales-role",
          desktop_copy:
            "Sales professionals generate revenue by delivering high-value product and service solutions that address customer problems. They utilize research, product knowledge, customer engagement skills, and team selling to drive success for both their team and buyers. ",
          mobile_copy:
            "Sales professionals generate revenue and strive to solve people’s problems with the best possible products and services. They help grow company-wide revenue and ensure customer success along the way.",
          top_skills: "Communication, Problem Solving, Project Management, Data Analysis, Client Relationship, Sales Practices",
          top_skills_mobile: [
            "Communication",
            "Problem Solving",
            "Project Management",
            "Data Analysis",
            "Client Relationship",
            "Sales Practices"
          ],
          download: "sales-professional",
          image: "/images/sales-pro.png",
          image_alt: "Headshot of a female Salesforce Sales professional, Nina Serr, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$65,000", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "108,140", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "37%", stat_text: "Projected Growth*" }
          ]
        },
        {
          title: "Service",
          main_title: "Service Professional",
          priority: 1,
          score_q1: 0,
          score_q2: 0,
          score_q3: 0,
          total_score: 0,
          url: "https://www.trailhead.com/service",
          url2: "https://trailhead.salesforce.com/content/learn/modules/service-role-quick-look/get-to-know-the-service-role",
          desktop_copy:
            "Customer service professionals provide exceptional customer experiences by solving customer problems with empathy and efficiency. They’re essential in driving brand loyalty and customer retention and act as the frontline of communication between customers and brands.",
          mobile_copy:
            "A Customer Service professional solves customer problems and inquiries to provide satisfactory customer experiences. They work to create a great experience for customers and drive retention.",
          top_skills: "Client Relationship, Customer Service, Communication, Problem Solving, Project Management, Sales Practices",
          top_skills_mobile: [
            "Client Relationship",
            "Customer Service",
            "Communication",
            "Problem Solving",
            "Project Management",
            "Sales Practices"
          ],
          download: "service-professional",
          image: "/images/service-pro.png",
          image_alt: "Headshot of a female Salesforce Service professional, Stephanie Barton, wearing a Trailblazer hoodie.",
          stats: [
            { stat_type: "salary", stat: "$53,000", stat_text: "Median Base Salary*" },
            { stat_type: "jobs", stat: "23,800", stat_text: "Open Jobs*" },
            { stat_type: "growth", stat: "48%", stat_text: "Projected Growth*" }
          ]
        }
      ],
      question1: "What value is most important to have at the heart of your work?",
      answers1: [
        {
          id: 1,
          text: "Adaptability",
          roles: ["Admin", "Business Analyst", "Consultant", "Cybersecurity", "Designer", "Developer", "Sales", "Service"]
        },
        {
          id: 2,
          text: "Empathy",
          roles: ["Consultant", "Marketer", "Service", "Sales", "Admin", "Business Analyst", "Designer", "Cybersecurity"]
        },
        {
          id: 3,
          text: "Innovation",
          roles: ["Developer", "Architect", "Designer", "Admin", "Business Analyst", "Marketer", "Consultant"]
        },
        {
          id: 4,
          text: "Efficiency",
          roles: ["Business Analyst", "Consultant", "Marketer", "Admin", "Sales", "Cybersecurity", "Architect"]
        }
      ],
      adaptability_q: "What's your favorite type of work?",
      empathy_q: "What work lights your fire?",
      efficiency_q: "Which work tasks do you enjoy most?",
      innovation_q: "What work ignites your passion?",
      adaptability_answers: [
        {
          id: 1,
          text: "Collaborating on effective solutions quickly",
          short_text: "Agile",
          roles: ["Business Analyst", "Designer", "Developer"]
        },
        {
          id: 2,
          text: "Identifying problems and finding solutions",
          short_text: "Problem Solving",
          roles: ["Sales", "Service", "Developer", "Cybersecurity"]
        },
        {
          id: 3,
          text: "Simplifying work and reducing manual tasks",
          short_text: "Process",
          roles: ["Admin", "Consultant", "Developer", "Business Analyst"]
        },
        {
          id: 4,
          text: "Building computer programs and helpful tools",
          short_text: "Development",
          roles: ["Consultant", "Developer"]
        },
        {
          id: 5,
          text: "Organizing the team to keep projects on track",
          short_text: "Project Management",
          roles: ["Cybersecurity", "Sales", "Service"]
        }
      ],
      empathy_answers: [
        {
          id: 1,
          text: "Helping clients and building strong connections",
          short_text: "Client Relationship",
          roles: ["Consultant", "Marketer", "Sales", "Service"]
        },
        {
          id: 2,
          text: "Speaking, writing, and sharing ideas clearly",
          short_text: "Communication",
          roles: ["Admin", "Business Analyst", "Cybersecurity", "Designer", "Marketer", "Sales", "Service"]
        },
        {
          id: 3,
          text: "Advising people on products and services they need",
          short_text: "Sales Practices",
          roles: ["Marketer", "Sales", "Service"]
        }
      ],
      efficiency_answers: [
        {
          id: 1,
          text: "Improving functions to make a company successful",
          short_text: "Business",
          roles: ["Business Analyst", "Consultant", "Marketer"]
        },
        {
          id: 2,
          text: "Working with data to make smart decisions",
          short_text: "Data",
          roles: ["Admin", "Sales"]
        },
        {
          id: 3,
          text: "Determining potential risks to mitigate them",
          short_text: "Security",
          roles: ["Admin", "Cybersecurity"]
        },
        {
          id: 4,
          text: "Ensuring end results meet customer expectations",
          short_text: "Quality",
          roles: ["Architect", "Business Analyst", "Consultant"]
        }
      ],
      innovation_answers: [
        {
          id: 1,
          text: "Turning tech ideas into reality and building solutions",
          short_text: "App Management",
          roles: ["Architect", "Developer"]
        },
        {
          id: 2,
          text: "Creating business solutions that put people first",
          short_text: "Design",
          roles: ["Admin", "Architect", "Business Analyst", "Designer", "Developer"]
        },
        {
          id: 3,
          text: "Promoting products and services to online customers",
          short_text: "Marketing",
          roles: ["Marketer", "Architect"]
        },
        {
          id: 4,
          text: "Developing and improving products and services",
          short_text: "Product",
          roles: ["Designer", "Consultant"]
        }
        // {
        //   id: 5,
        //   text: "I love discovering and sharing new ideas",
        //   short_text: "Research",
        //   roles: ["Designer"]
        // }
      ]
    };
  },
  getters: {
    score_sorted_with_tiebreaker(state) {
      const customCompare = (a, b) => {
        if (a.total_score === b.total_score) {
          return a.priority < b.priority ? -1 : 1;
        } else {
          return a.total_score < b.total_score ? 1 : -1;
        }
      };
      const sorted = state.quiz_score.sort(customCompare);
      if (state.tiebreaker_calc) {
        // const tied = sorted.filter((a) => a.total_score === sorted[0].total_score);
        // console.log(sorted[0]);
        // console.log(tied.length);
        sorted[0].total_score += 5;
        state.tiebreaker_calc = false;
      }
      return sorted;
    },
    score_sorted: (state) => state.quiz_score.sort((a, b) => (a.total_score < b.total_score ? 1 : -1)),
    top_percent(state) {
      let result = Math.round(
        (state.score_sorted[0].total_score /
          (state.score_sorted[0].total_score + state.score_sorted[1].total_score + state.score_sorted[2].total_score)) *
          100
      );
      if (result === 33) {
        result = 34;
      }
      return result;
    },
    second_percent: (state) =>
      Math.round(
        (state.score_sorted[1].total_score /
          (state.score_sorted[0].total_score + state.score_sorted[1].total_score + state.score_sorted[2].total_score)) *
          100
      ),
    third_percent: (state) =>
      Math.round(
        (state.score_sorted[2].total_score /
          (state.score_sorted[0].total_score + state.score_sorted[1].total_score + state.score_sorted[2].total_score)) *
          100
      ),
    random_url_best_match(state) {
      let random_url = state.score_sorted[0].url
      const urls = [state.score_sorted[0].url, state.score_sorted[0].url2];
      for (let i = 0; i < urls.length; i++) {
        random_url = urls.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
      }
      return random_url;
    },
    share_url(state) {
      return `https://trailblazer-career-match.herokuapp.com/${state.score_sorted[0].download}`;
    }
  },
  // persist: true,
  persist: {
    key: "quiz",
    storage: window.sessionStorage,
    paths: [
      "step",
      "quiz_started",
      "question2_started",
      "question3_started",
      "quiz_score",
      "answers1",
      "adaptability_answers",
      "empathy_answers",
      "efficiency_answers",
      "innovation_answers",
      "quiz_submitted",
      "quiz_id"
    ]
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    restartQuiz() {
      this.step = 1;
      this.quiz_started = false;
      this.question2_started = false;
      this.question3_started = false;
      this.show_panel = true;
      this.tiebreaker_calc = false;
      // randomize(this.answers1);
      // this.quiz_score.forEach((e) => {
      //   e.score = 0;
      // });
      this.quiz_score.forEach((e) => {
        e.score_q1 = 0;
        e.score_q2 = 0;
        e.score_q3 = 0;
      });
      this.calculateScore_q1();
      window.location.href = "/quiz-values";
    },
    increment() {
      this.count++;
    },
    calculateScore_q1() {
      this.answers1.forEach((e, i) => {
        // console.log(`${i}: ${e.text} / ${e.roles}`);
        if (i === 0) {
          this.quiz_score.forEach((role) => {
            role.score_q1 = 0;
          });
        }
        e.roles.forEach((role) => {
          const e_score = this.quiz_score.find((x) => x.title === role);
          if (i === 0) {
            e_score.score_q1 = e_score.score_q1 + 3;
          } else if (i === 1) {
            e_score.score_q1 = e_score.score_q1 + 2;
          } else if (i === 2) {
            e_score.score_q1 = e_score.score_q1 + 1;
          }
          // console.log(`${i}: ${e_score.score_q1}`);
          // console.log(e_score);
        });
      });
      this.calculateTotal();
    },
    calculateScore_q2() {
      if (this.answers1[0].id === 1) {
        this.adaptability_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q2 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q2 = e_score.score_q2 + 2;
            } else if (i === 1) {
              e_score.score_q2 = e_score.score_q2 + 1;
            }
          });
        });
      } else if (this.answers1[0].id === 2) {
        this.empathy_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q2 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q2 = e_score.score_q2 + 2;
            } else if (i === 1) {
              e_score.score_q2 = e_score.score_q2 + 1;
            }
          });
        });
      } else if (this.answers1[0].id === 3) {
        this.innovation_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q2 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q2 = e_score.score_q2 + 4;
            } else if (i === 1) {
              e_score.score_q2 = e_score.score_q2 + 1;
            }
          });
        });
      } else if (this.answers1[0].id === 4) {
        this.efficiency_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q2 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q2 = e_score.score_q2 + 4;
            } else if (i === 1) {
              e_score.score_q2 = e_score.score_q2 + 1;
            }
          });
        });
      }
      this.calculateTotal();
    },
    calculateScore_q3() {
      if (this.answers1[1].id === 1) {
        this.adaptability_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q3 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q3 = e_score.score_q3 + 2;
            } else if (i === 1) {
              e_score.score_q3 = e_score.score_q3 + 1;
            }
          });
        });
      } else if (this.answers1[1].id === 2) {
        this.empathy_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q3 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q3 = e_score.score_q3 + 2;
            } else if (i === 1) {
              e_score.score_q3 = e_score.score_q3 + 1;
            }
          });
        });
      } else if (this.answers1[1].id === 3) {
        this.innovation_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q3 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q3 = e_score.score_q3 + 2;
            } else if (i === 1) {
              e_score.score_q3 = e_score.score_q3 + 1;
            }
          });
        });
      } else if (this.answers1[1].id === 4) {
        this.efficiency_answers.forEach((e, i) => {
          if (i === 0) {
            this.quiz_score.forEach((role) => {
              role.score_q3 = 0;
            });
          }
          e.roles.forEach((role) => {
            const e_score = this.quiz_score.find((x) => x.title === role);
            if (i === 0) {
              e_score.score_q3 = e_score.score_q3 + 2;
            } else if (i === 1) {
              e_score.score_q3 = e_score.score_q3 + 1;
            }
          });
        });
      }
      this.calculateFinal();
    },
    calculateTotal() {
      this.quiz_score.forEach((role) => {
        role.total_score = role.score_q1 + role.score_q2 + role.score_q3;
      });
      // cookies.set("top_match", this.score_sorted[0].title);
    },
    calculateFinal() {
      this.tiebreaker_calc = true;
      this.quiz_score.forEach((role) => {
        role.total_score = role.score_q1 + role.score_q2 + role.score_q3;
      });
      cookies.set("top_match", this.score_sorted[0].title);
      cookies.set(
        "image_share_url",
        `https://trailblazer-career-match.herokuapp.com/images/downloads/${this.score_sorted[0].download}.jpg`
      );
    },
    async link_clicked(url) {
      const form_data = {
        link_clicked: url
      };
      console.log(form_data);
      try {
        axios
          .patch(`/quizzes/${this.quiz_id}/set_link_clicked`, form_data)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        this.error_msg = "Something went wrong. Please go back and try again.";
        console.log(error);
        // let the form component display the error
        return error;
      }
    },
    async post_quiz(type) {
      const default_options = {
        first: this.score_sorted[0].title,
        second: this.score_sorted[1].title,
        third: this.score_sorted[2].title
      };
      let updated = { updated: false };
      if (type === "update") {
        updated = { updated: true };
      }
      const form_data = {
        ...default_options,
        ...updated
      };
      console.log(form_data, type);
      const self = this;
      if (type === "create") {
        try {
          axios
            .post("/quizzes", form_data)
            .then(function (response) {
              console.log(response.data.id);
              self.quiz_submitted = true;
              self.quiz_id = response.data.id;
              window.location.href = "/results";
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (error) {
          // this.error_msg = "Something went wrong. Please go back and try again.";
          console.log(error);
          // let the form component display the error
          return error;
        }
      } else {
        try {
          axios
            .patch(`/quizzes/${this.quiz_id}`, form_data)
            .then(function (response) {
              console.log(response);
              window.location.href = "/results";
            })
            .catch(function (error) {
              console.log(error);
            });
        } catch (error) {
          this.error_msg = "Something went wrong. Please go back and try again.";
          console.log(error);
          // let the form component display the error
          return error;
        }
      }
    },
    calculateScore(page, step) {
      // console.log(this.answers1);
      if (step === 1) {
        this.answers1.forEach((e, i) => {
          if (i < 2) {
            console.log(`${i}: ${e.text} / ${e.roles}`);
            e.roles.forEach((role) => {
              const e_score = this.quiz_score.find((x) => x.title === role);
              if (i === 0) {
                e_score.score = e_score.score + 2;
              } else {
                e_score.score = e_score.score + 1;
              }
              console.log(e_score);
            });
          }
        });
      } else if (step === 2) {
        if (this.answers1[0].id === 1) {
          this.adaptability_answers.forEach((e, i) => {
            if (i < 2) {
              console.log(`${i}: ${e.text} / ${e.roles}`);
              e.roles.forEach((role) => {
                const e_score = this.quiz_score.find((x) => x.title === role);
                if (i === 0) {
                  e_score.score = e_score.score + 2;
                } else {
                  e_score.score = e_score.score + 1;
                }
                console.log(e_score);
              });
            }
          });
        } else if (this.answers1[0].id === 2) {
          this.empathy_answers.forEach((e, i) => {
            if (i < 2) {
              console.log(`${i}: ${e.text} / ${e.roles}`);
              e.roles.forEach((role) => {
                const e_score = this.quiz_score.find((x) => x.title === role);
                if (i === 0) {
                  e_score.score = e_score.score + 2;
                } else {
                  e_score.score = e_score.score + 1;
                }
                console.log(e_score);
              });
            }
          });
        } else if (this.answers1[0].id === 3) {
          this.innovation_answers.forEach((e, i) => {
            if (i < 2) {
              console.log(`${i}: ${e.text} / ${e.roles}`);
              e.roles.forEach((role) => {
                const e_score = this.quiz_score.find((x) => x.title === role);
                if (i === 0) {
                  e_score.score = e_score.score + 2;
                } else {
                  e_score.score = e_score.score + 1;
                }
                console.log(e_score);
              });
            }
          });
        } else if (this.answers1[0].id === 4) {
          this.efficiency_answers.forEach((e, i) => {
            if (i < 2) {
              console.log(`${i}: ${e.text} / ${e.roles}`);
              e.roles.forEach((role) => {
                const e_score = this.quiz_score.find((x) => x.title === role);
                if (i === 0) {
                  e_score.score = e_score.score + 2;
                } else {
                  e_score.score = e_score.score + 1;
                }
                console.log(e_score);
              });
            }
          });
        } else if (step === 3) {
          if (this.answers1[1].id === 1) {
            this.adaptability_answers.forEach((e, i) => {
              if (i < 2) {
                console.log(`${i}: ${e.text} / ${e.roles}`);
                e.roles.forEach((role) => {
                  const e_score = this.quiz_score.find((x) => x.title === role);
                  if (i === 0) {
                    e_score.score = e_score.score + 2;
                  } else {
                    e_score.score = e_score.score + 1;
                  }
                  console.log(e_score);
                });
              }
            });
          } else if (this.answers1[1].id === 2) {
            this.empathy_answers.forEach((e, i) => {
              if (i < 2) {
                console.log(`${i}: ${e.text} / ${e.roles}`);
                e.roles.forEach((role) => {
                  const e_score = this.quiz_score.find((x) => x.title === role);
                  if (i === 0) {
                    e_score.score = e_score.score + 2;
                  } else {
                    e_score.score = e_score.score + 1;
                  }
                  console.log(e_score);
                });
              }
            });
          } else if (this.answers1[1].id === 3) {
            this.innovation_answers.forEach((e, i) => {
              if (i < 2) {
                console.log(`${i}: ${e.text} / ${e.roles}`);
                e.roles.forEach((role) => {
                  const e_score = this.quiz_score.find((x) => x.title === role);
                  if (i === 0) {
                    e_score.score = e_score.score + 2;
                  } else {
                    e_score.score = e_score.score + 1;
                  }
                  console.log(e_score);
                });
              }
            });
          } else if (this.answers1[1].id === 4) {
            this.efficiency_answers.forEach((e, i) => {
              if (i < 2) {
                console.log(`${i}: ${e.text} / ${e.roles}`);
                e.roles.forEach((role) => {
                  const e_score = this.quiz_score.find((x) => x.title === role);
                  if (i === 0) {
                    e_score.score = e_score.score + 2;
                  } else {
                    e_score.score = e_score.score + 1;
                  }
                  console.log(e_score);
                });
              }
            });
          }
        }
      }
      this.step++;
      if (this.step == 2) {
        window.location.href = "/quiz-purpose";
      } else if (this.step == 3) {
        window.location.href = "/quiz-preferences";
      } else if (this.step == 4) {
        window.location.href = "/results";
      }
    }
  }
});
